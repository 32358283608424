import { ConfigProvider } from "antd";
import App from "./App";

const ThemedApp = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemBg: "transparent",
            fontSize: 16,
            itemColor: "#3B87F7",
            itemHoverColor: "#3B87F7",
          },
          Form: {
            itemMarginBottom: 12,
          },
          Input: {
            colorBgContainer: "#37373C",
            colorBorder: "#37373C",
            colorIcon: "white",
            colorText: "white",
            borderRadius: 8,
            colorTextPlaceholder: "#7C7C82",
          },
          Button: {
            fontWeight: 600,
            fontSize: 16,
          },
          Table: {
            headerBg: "trasnparent",
            colorBgContainer: "transparent",
            headerSplitColor: "transparent",
            cellFontSize: 16,
            colorText: "white",
            headerColor: "#9B9CA3",
            borderColor: "transparent",
            cellPaddingBlock: 10,
          },
          Pagination: {
            itemActiveBg: "#3B87F7",
            colorText: "white",
            itemBg: "red",
            colorPrimary: "white",
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  );
};

export default ThemedApp;
