import { Button, Col, ConfigProvider, Menu, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../redux/reducers";
import Item from "antd/es/descriptions/Item";
import { Link } from "react-router-dom";
import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import shidologo from "../assets/shidoLogo.svg";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const items: MenuProps["items"] = [
    {
      label: "Users",
      key: "mail",
      onClick: () => {
        navigate("/Users");
      },
    },
    {
      label: "Pools",
      key: "app",
      onClick: () => {
        navigate("/Pools");
      },
    },
  ];

  return (
    <Row
      style={{
        backgroundColor: "#252527",
        padding: "24px",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "12px",
        marginBottom: "20px",
      }}
    >
      <img src={shidologo} style={{ width: "175px", height: "48px" }} />
      <Col style={{ display: "flex", alignItems: "center", gap: "30px" }}>
        <Menu mode="horizontal" items={items} />
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorBgContainer: "#37373C",
                colorBorder: "#37373C",
                colorText: "white",
                borderRadius: 8,
                fontSize: 16,
                paddingBlock: 56,
                paddingInlineLG: 26,
              },
            },
          }}
        >
          <Button
            onClick={() => {
              dispatch(logout());
            }}
            size="large"
          >
            Logout
          </Button>
        </ConfigProvider>
      </Col>
    </Row>
  );
};

export default Header;
