import React from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Row,
  message,
  theme,
} from "antd";
import { useDispatch } from "react-redux";
import { login } from "../redux/reducers";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { LoginAPI } from "../api/ApiCalls";
import shidologo from "../assets/shidoLogo.svg";

const Login = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const error = (errMessage: string) => {
    messageApi.open({
      type: "error",
      content: errMessage,
    });
  };
  const test = async (values: any) => {
    try {
      const res = await LoginAPI({
        email: values.username,
        password: values.password,
      });
      if (res) {
        const payload = {
          user: res.data.wallet_address,
          isAuthenticated: true,
          token: res.data.access_token,
          refreshToken: res.data.refresh_token,
        };
        dispatch(login(payload));
      }
      console.log("res inside login", res);
    } catch (err: any) {
      const errMessage = err.response.data.message;
      error(errMessage);
      console.log(errMessage);
    }
  };

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    test(values);
  };
  return (
    <Layout
      style={{
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#181819",
      }}
    >
      {contextHolder}
      <Row
        style={{
          maxWidth: "400px",
          padding: "36px",
          backgroundColor: "#252527",
          borderRadius: "12px",
          justifyContent: "center",
        }}
      >
        <img
          src={shidologo}
          style={{ width: "206px", height: "56px", marginBottom: "36px" }}
        />
        <Col span={24} style={{ textAlign: "center" }}>
          <p
            style={{
              color: "white",
              fontWeight: "600",
              fontSize: "26px",
              margin: 0,
              marginBottom: "20px",
            }}
          >
            Login
          </p>
        </Col>
        <Col span={24}>
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input placeholder="Email" size="large" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                type="password"
                placeholder="Password"
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Button
                style={{ width: "100%", marginTop: "24px" }}
                type="primary"
                htmlType="submit"
                size="large"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;
