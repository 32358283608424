import React from "react";
import "./App.scss";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./pages/Login";
import { useSelector } from "react-redux";
import { userState } from "./redux/reducers";
import Header from "./components/Header";
import Users from "./pages/Users";
import Pools from "./pages/Pools";

function App() {
  const user = useSelector(userState);
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        {user.isAuthenticated ? <LoggedInRoutes /> : <NotLoggedInRoutes />}
        {/* {false ? <LoggedInRoutes /> : <NotLoggedInRoutes />} */}
      </Layout>
    </Router>
  );
}

export default App;

const LoggedInRoutes = () => {
  return (
    <Layout style={{ backgroundColor: "#181819", padding: "20px" }}>
      <Header />
      <Content>
        <Routes>
          <Route path="*" element={<Users />} />
          <Route path="/Pools" element={<Pools />} />
        </Routes>
      </Content>
    </Layout>
  );
};

const NotLoggedInRoutes = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    </Layout>
  );
};
