import axios from "../axios";

interface UserData {
  id: number;
  wallet_address: string;
  last_login: string;
  date_joined: string;
}

type UserArray = UserData[];

export const GetAllUsersAPI = async () => {
  try {
    const endPoint = "/user/all-users/";
    const res = await axios.get<UserArray>(endPoint);
    if (!res?.data) throw "Something went wrong";

    return res.data;
  } catch (err: any) {
    console.log("error while AllUsers API>>>", err.response.data);
    return Promise.reject(err);
  }
};
