import axios from "../axios";

export const GetAllPools = async () => {
  try {
    const endPoint = "/pools/";
    const res = await axios.get<Pool[]>(endPoint);
    if (!res?.data) throw "Something went wrong";

    return res.data;
  } catch (err: any) {
    console.log("error while ALL POOls API>>>", err.response.data);
    return Promise.reject(err);
  }
};

interface Pool {
  type: string;
  owner: number;
  swap_fee: number;
  contract_address: string;
  tokens: number[];
}
