import { Button, Col, Layout, Row, Table, TableColumnsType } from "antd";
import React, { useEffect, useState } from "react";
import { GetAllUsersAPI } from "../api/ApiCalls";
import moment from "moment";
import { logout } from "../redux/reducers";
import { useDispatch } from "react-redux";

const columns: TableColumnsType<UserData> = [
  {
    title: "Wallet Address",
    dataIndex: "wallet_address",
    render: (text: string) => <>{text}</>,
  },
  {
    title: "Last Login",
    dataIndex: "last_login",
    render: (text: string) => (
      <span style={{ whiteSpace: "pre-wrap" }}>
        {moment(text).format("DD-MM-YYYY    h:mm A")}
      </span>
    ),
  },
  {
    title: "Created At",
    dataIndex: "date_joined",
    render: (text: string) => (
      <span style={{ whiteSpace: "pre-wrap" }}>
        {moment(text).format("DD-MM-YYYY    h:mm A")}
      </span>
    ),
  },
];

const Users = () => {
  const [tabelData, setTabelData] = useState<UserArray>([]);
  const dispatch = useDispatch();

  const fetchAllUser = async () => {
    try {
      const res = await GetAllUsersAPI();
      if (res) {
        setTabelData(res);
      }
    } catch (error) {
      console.log("Error while fetch all User>>>>>", error);
    }
  };

  useEffect(() => {
    fetchAllUser();
  }, []);
  return (
    <Layout
      style={{
        backgroundColor: "#252527",
        height: "100%",
        borderRadius: "12px",
      }}
    >
      <Row style={{ padding: "24px" }}>
        <h4
          style={{
            fontSize: "24px",
            fontWeight: "600",
            margin: 0,
            color: "white",
            marginBottom: "24px",
          }}
        >
          All users
        </h4>
        <Table
          pagination={{ pageSize: 8 }}
          style={{ width: "100%" }}
          columns={columns}
          dataSource={tabelData.map((userdata) => ({
            ...userdata,
            key: userdata.id,
          }))}
        />
      </Row>
    </Layout>
  );
};

export default Users;

interface UserData {
  id: number;
  wallet_address: string;
  last_login: string;
  date_joined: string;
}

type UserArray = UserData[];
