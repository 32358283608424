import {
  camelCase,
  each,
  isArray,
  isEmpty,
  isObject,
  isString,
  snakeCase,
  startCase,
} from 'lodash';

const transform = (body: any, transformFn: any, type: any) => {
  const transformObj = (obj: any) => {
    each(obj, (value, key) => {
      delete obj[key];
      if (type === 'snakeCase') {
        switch (key) {
          default:
            if (isString(key) && key.includes('/')) {
              obj[key] = value;
            } else {
              obj[transformFn(key)] = value;
            }

            if (!isString(value)) {
              transformObj(value);
            }
            break;
        }
      } else {
        if (isString(key) && key.includes('/')) {
          obj[key] = value;
        } else {
          obj[transformFn(key)] = value;
        }

        if (!isString(value)) {
          transformObj(value);
        }
      }
    });
  };

  if (isArray(body)) {
    each(body, (obj) => {
      transformObj(obj);
    });
  } else if (isObject(body)) {
    transformObj(body);
  }

  return body;
};

const transformKeys = {
  toCamelCase: (body: any) => {
    return isEmpty(body) ? body : transform(body, camelCase, 'camelCase');
  },
  toSnakeCase: (body: any) => {
    return isEmpty(body) ? body : transform(body, snakeCase, 'snakeCase');
  },
  toTitleCase: (body: any) => {
    return isEmpty(body) ? body : transform(body, startCase, 'startCase');
  },
};

export default transformKeys;
