import { Layout, Row, Table, TableColumnsType } from "antd";
import { useEffect, useState } from "react";
import { GetAllPools } from "../api/ApiCalls";

import { useDispatch } from "react-redux";

const columns: TableColumnsType<Pool> = [
  {
    title: "Contract Address",
    dataIndex: "contract_address",
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Tokens",
    dataIndex: "tokens",
    render: (text: number[]) => <span>{text.join(" | ")}</span>,
  },

  {
    title: "Swap Fee",
    dataIndex: "swap_fee",
  },
  {
    title: "Owner",
    dataIndex: "owner",
  },
];

const Pools = () => {
  const [tabelData, setTabelData] = useState<UserArray>([]);
  const dispatch = useDispatch();

  const fetchAllPools = async () => {
    try {
      const res = await GetAllPools();
      if (res) {
        setTabelData(res);
      }
    } catch (error) {
      console.log("Error while fetch Pool>>>>>", error);
    }
  };

  useEffect(() => {
    fetchAllPools();
  }, []);
  return (
    <Layout
      style={{
        backgroundColor: "#252527",
        height: "100%",
        borderRadius: "12px",
      }}
    >
      <Row style={{ padding: "24px" }}>
        <h4
          style={{
            fontSize: "24px",
            fontWeight: "600",
            margin: 0,
            color: "white",
            marginBottom: "24px",
          }}
        >
          All Pools
        </h4>
        <Table
          pagination={{ pageSize: 8 }}
          style={{ width: "100%" }}
          columns={columns}
          dataSource={tabelData.map((userdata, dataIndex) => ({
            ...userdata,
            key: dataIndex,
          }))}
        />
      </Row>
    </Layout>
  );
};

export default Pools;
interface Pool {
  type: string;
  owner: number;
  swap_fee: number;
  contract_address: string;
  tokens: number[];
}

type UserArray = Pool[];
